import React from "react";
import { Link } from "gatsby";
import { PrimaryAlternativeBtn } from "components/library";
import IllusInnovation from "images/illus_innovation.svg";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";

export const ContactUsMediaCard = () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col sm:flex-row justify-between gap-6 rounded-md bg-gradient-to-r from-[#305CBD] to-[#F8CCA9] px-12 text-white items-center self-center w-full">
      <div className="flex flex-col gap-5 text-center sm:text-left m-6">
        <h2 className="text-4xl md:text-5xl text-neutrals-n0 text-left">
          {t("contact-us.content.question-for-us")}
        </h2>
        <p className="text-neutrals-200 md:text-lg text-left">
          <Trans
            i18nKey="contact-us.content.check-resources"
            components={{
              anchor: <Link to="/support" />,
            }}
          />
        </p>
        <Link to="/contact-us/" className="w-full sm:max-w-max">
          <PrimaryAlternativeBtn className="md:text-lg ring-theme-grey w-full font-bold rounded-lg hover:text-white">
            {t("common.words.contact-us")}
          </PrimaryAlternativeBtn>
        </Link>
      </div>

      <img src={IllusInnovation} className="hidden md:block" />
    </div>
  );
};
