import React from 'react'
import { graphql } from 'gatsby'
import { NewsroomHero } from "components/pages/newsroom/NewsroomHero";
import { FeaturedArticle } from "components/pages/newsroom/FeaturedArticle";
import { Container } from "components/library";
import { ArticlesCarousel } from "components/pages/newsroom/ArticlesCarousel";
import { ContactUsMediaCard } from "components/pages/newsroom/ContactUsMediaCard";
import { NotionArticle } from "utils";
import { useTranslation } from 'react-i18next';
import { CtaBlock } from 'components/common/cta-block';

interface P {
  pageContext: {
    mainFeaturedArticle: NotionArticle,
    otherFeaturedArticles: NotionArticle[]
    recentlyPublishedArticles: NotionArticle[]
  }
}

const Media = ({ pageContext }: P) => {
  const { t } = useTranslation()
  const {
    mainFeaturedArticle,
    otherFeaturedArticles,
    recentlyPublishedArticles,
  } = pageContext

  return (
    <main className="-mt-28 sm:-mt-36 flex flex-col">
      <div className="bg-newsroom-bg bg-no-repeat bg-cover w-full flex flex-col mb-20 gap-7 pt-40 sm:pt-60">
        <NewsroomHero />
        <FeaturedArticle article={mainFeaturedArticle} />
      </div>

      <Container className='flex flex-col gap-20 mb-20 mt-9'>
        {otherFeaturedArticles.length !== 0 &&
          <ArticlesCarousel title={t("newsroom.sections.featured.title")} subtitle={t("newsroom.sections.featured.subtitle")}
            articles={otherFeaturedArticles}
            type='default' />

        }

        {recentlyPublishedArticles.length !== 0 &&
          <ArticlesCarousel title={t("newsroom.sections.recently-published.title")} subtitle={t("newsroom.sections.recently-published.subtitle")}
            articles={recentlyPublishedArticles}
            type='default' />
        }

        <ContactUsMediaCard />

      </Container>

      <CtaBlock className="mt-20" />
    </main>
  )
}

export default Media

// FOR LANGUAGE TRANSLATION
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    allFaq(filter: {categories: {elemMatch: {name: {eq: "Website Polymerize Labs"}}}}) {
      nodes {
        id
        question
        answer
      }
    }
  }
`