import React, { ComponentPropsWithRef, forwardRef, memo, NamedExoticComponent } from "react"
import { mergeClasses } from "utils"

type P = ComponentPropsWithRef<"span"> & {
  label?: string
  ignoreClasses?: string
}

export const Chip: NamedExoticComponent<P> = memo(
  forwardRef(({ className = "", children, label, ignoreClasses, ...props }, ref) => {
    return (
      <span
        className={
          mergeClasses({
            classes: ["select-none text-xs rounded bg-[#EBECF0] text-neutral-600 py-1 px-4  max-w-max", className],
            ignore: ignoreClasses
          })
        }
        ref={ref}
        {...props}
      >
                {label ? label : children}
            </span>
    )
  }),
)

Chip.displayName = "Chip Component"
