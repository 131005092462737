import React from "react";
import { Container } from "components/library";
import { useTranslation } from "react-i18next";

export const NewsroomHero = () => {
  const { t } = useTranslation();
  return (
    <Container className="flex flex-col text-white gap-4 justify-between">
      <h1 className="tracking-wider font-normal text-5xl md:text-7xl">
        {t("newsroom.title")}
      </h1>
      <p className="sm:text-lg sm:max-w-xl text-xl md:text-2xl md:whitespace-nowrap">
        {t("newsroom.desc")}
      </p>
      <hr className="border-white mt-3" />
    </Container>
  );
};
