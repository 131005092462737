import React, { ComponentPropsWithoutRef, useState } from "react"
import { NotionArticle, useArticle } from "utils"
import { ArrowRight } from "react-feather"
import { mergeClasses } from "utils"
import { Link, useTranslation } from "gatsby-plugin-react-i18next"
import { Chip } from "components/common/Chip";
import moment from "moment"
import { PrimaryAlternativeBtn } from "components/library"

type P = ComponentPropsWithoutRef<"div"> & {
	article: NotionArticle
	type: "default" | "no-image" | "full"
	imageClass?: string
	link?: string
	title?: string
	excerpt?: string
}

export const ArticleCard = ({
	title: cardTitle,
	excerpt: cardExcerpt,
	article,
	link: webLink,
	type,
	className = "",
	imageClass = "",
	...props
}: P) => {
	const { title: articleTitle, excerpt: articleExcerpt, image, categories, timeToRead, publishedDate, link: blogLink } = useArticle({ article })
	const link = webLink ?? blogLink
	const title = cardTitle ?? articleTitle
	const excerpt = cardExcerpt ?? articleExcerpt

	const { t } = useTranslation()

	return (
		<Link to={link}
			className={mergeClasses({
				classes: [
					`w-full max-w-[384px] flex flex-col shadow-md rounded-md overflow-hidden bg-white`,
					className,
				],
			})}
			{...props}
		>
			{(type === "default" || type === "full") && (
				<Link to={link}>
					<img
						src={image}
						className={mergeClasses({
							classes: [
								"shrink-0 object-cover h-auto sm:h-48 max-h-[350px] w-full",
								imageClass,
							],
						})}
						alt={title}
					/>
				</Link>
			)}
			<div className="flex flex-col gap-7 p-6 grow">
				{type === 'full' && (
					<p className="text-[#9FA8B7] flex gap-2 text-xs select-none">
						<span className="block xl:inline">{moment(publishedDate).format("MMMM Do YYYY")}</span>
						<span>·</span>
						<span>{timeToRead}</span>
					</p>
				)}

				<div className='flex flex-col gap-2 grow'>
					{type === "full" && (
						<span className='flex gap-2 flex-wrap'>
							{categories?.map((category) => {
								return (
									<Chip label={category} />
								)
							})}
						</span>
					)}
					<Link to={link}>
						<div className="flex flex-col gap-2 grow">
							<p className="text-xl font-semibold text-neutral-700 text-left">{title}</p>
							<p className="text-neutral-500 text-sm text-left">{excerpt}</p>
						</div>
					</Link>
				</div>

				<div className="flex justify-between select-none mt-auto">
					<Link to={link} className="w-full lg:w-auto">
						<PrimaryAlternativeBtn className="flex items-center justify-center gap-2 border-0 text-theme-orange hover:bg-theme-orange/80 hover:text-white">
							<span>{t("common.cta.read-more")}</span>
							<ArrowRight size={16} strokeWidth={3} />
						</PrimaryAlternativeBtn>
					</Link>
				</div>
			</div>
		</Link>
	)
}
